import React from 'react'

export const Ecomix130 = ({ ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1366"
    height="691"
    viewBox="0 0 1366 691"
    {...props}
  >
    <defs>
      <pattern
        id="pattern"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 212 188"
      >
        <image
          width="212"
          height="188"
          href="../ecomix-130/PA01.121.13E.3211_3.png"
        />
      </pattern>
      <pattern
        id="pattern-2"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 159 183"
      >
        <image
          width="159"
          height="183"
          href="../ecomix-130/PA01.121.13E.3211_2.png"
        />
      </pattern>
      <pattern
        id="pattern-3"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 139 183"
      >
        <image
          width="139"
          height="183"
          href="../ecomix-130/PA01.121.13E.3211_4.png"
        />
      </pattern>
      <pattern
        id="pattern-4"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 18 22"
      >
        <image
          width="18"
          height="22"
          href="../ecomix-130/PA01.121.13E.3211_8.png"
        />
      </pattern>
      <pattern
        id="pattern-5"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 44 29"
      >
        <image
          width="44"
          height="29"
          href="../ecomix-130/PA01.121.13E.3211_9.png"
        />
      </pattern>
      <pattern
        id="pattern-6"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 17 19"
      >
        <image
          width="17"
          height="19"
          href="../ecomix-130/PA01.121.13E.3211_5.png"
        />
      </pattern>
      <pattern
        id="pattern-7"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 38 28"
      >
        <image
          width="38"
          height="28"
          href="../ecomix-130/PA01.121.13E.3211_7.png"
        />
      </pattern>
      <pattern
        id="pattern-8"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 34 23"
      >
        <image
          width="34"
          height="23"
          href="../ecomix-130/PA01.121.13E.3211_10.png"
        />
      </pattern>
      <pattern
        id="pattern-9"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 72"
      >
        <image
          width="40"
          height="72"
          href="../ecomix-130/PA01.121.13E.3211_16.png"
        />
      </pattern>
      <pattern
        id="pattern-10"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 114 145"
      >
        <image
          width="114"
          height="145"
          href="../ecomix-130/PA01.121.13E.3211_15.png"
        />
      </pattern>
      <pattern
        id="pattern-11"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 37 25"
      >
        <image
          width="37"
          height="25"
          href="../ecomix-130/PA01.121.13E.3211_13.png"
        />
      </pattern>
      <pattern
        id="pattern-12"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 40 43"
      >
        <image
          width="40"
          height="43"
          href="../ecomix-130/PA01.121.13E.3211_1.jpg"
        />
      </pattern>
      <pattern
        id="pattern-13"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 46 60"
      >
        <image
          width="46"
          height="60"
          href="../ecomix-130/PA01.121.13E.3211_11.png"
        />
      </pattern>
      <pattern
        id="pattern-14"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 125 145"
      >
        <image
          width="125"
          height="145"
          href="../ecomix-130/PA01.121.13E.3211_19.png"
        />
      </pattern>
      <pattern
        id="pattern-15"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 257 263"
      >
        <image
          width="257"
          height="263"
          href="../ecomix-130/PA01.121.13E.3211_14.png"
        />
      </pattern>
      <pattern
        id="pattern-16"
        preserveAspectRatio="none"
        width="100%"
        height="100%"
        viewBox="0 0 197 230"
      >
        <image
          width="197"
          height="230"
          href="../ecomix-130/PA01.121.13E.3211_18.png"
        />
      </pattern>
    </defs>
    <g
      className="vista-g"
      id="Group_82"
      data-name="Group 82"
      transform="translate(-323 -192)"
    >
      <rect
        id="PA01.121.13E.3211_3"
        width="270"
        height="240"
        transform="translate(576 192)"
        fill="url(#pattern)"
      />
      <rect
        id="PA01.121.13E.3211_2"
        width="203"
        height="233"
        transform="translate(323 239)"
        fill="url(#pattern-2)"
        data-url="https://shopirbal.com/pt/produto/cremalheira-b1-laranja-125-140/"
        data-name="Cremalheira de Ferro Fundido 130-150-155"
      />
      <rect
        id="PA01.121.13E.3211_4"
        width="177"
        height="234"
        transform="translate(513 649)"
        fill="url(#pattern-3)"
      />
      <image
        id="PA01.121.13E.3211_12"
        width="372"
        height="515"
        transform="translate(879 363)"
        href="../ecomix-130/PA01.121.13E.3211_12.png"
      />
      <rect
        id="PA01.121.13E.3211_8"
        width="23"
        height="28"
        transform="translate(960 284)"
        fill="url(#pattern-4)"
        data-url="https://shopirbal.com/pt/produto/interruptor-b1/"
        data-name="Interruptor B1"
      />
      <rect
        id="PA01.121.13E.3211_9"
        width="56"
        height="37"
        transform="translate(781 503)"
        fill="url(#pattern-5)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6006-2rs/"
        data-name="Rolamento do Cubo do Balde 6006 2RS"
      />
      <rect
        id="PA01.121.13E.3211_5"
        width="21"
        height="24"
        transform="translate(726 586)"
        fill="url(#pattern-6)"
      />
      <rect
        id="PA01.121.13E.3211_7"
        width="48"
        height="36"
        transform="translate(998 377)"
        fill="url(#pattern-7)"
        data-url="https://shopirbal.com/pt/produto/pinhao-da-cremalheira-b125-cavilha/"
        data-name="Pinhão/Carreto B130 + Cavilha"
      />
      <rect
        id="PA01.121.13E.3211_10"
        width="44"
        height="29"
        transform="translate(1258 428)"
        fill="url(#pattern-8)"
        data-url="https://shopirbal.com/pt/produto/rolamento-6003-2rs/"
        data-name="Rolamento do Lado do Pinhão RL 6003 2RS"
      />
      <rect
        id="PA01.121.13E.3211_16"
        width="50"
        height="91"
        transform="translate(1235 227)"
        fill="url(#pattern-9)"
        data-url="https://shopirbal.com/pt/produto/correia-6pj-636-elastica/"
        data-name="Correia 6PJ 636 Elástica"
      />
      <rect
        id="PA01.121.13E.3211_15"
        width="146"
        height="185"
        transform="translate(1412 201)"
        fill="url(#pattern-10)"
      />
      <rect
        id="PA01.121.13E.3211_13"
        width="46"
        height="31"
        transform="translate(1321 618)"
        fill="url(#pattern-11)"
      />
      <g id="PA01.121.13E.3211_1" transform="translate(1312 332)">
        <rect
          id="Image_10"
          data-name="Image 10"
          width="63"
          height="68"
          fill="url(#pattern-12)"
        />
      </g>
      <rect
        id="PA01.121.13E.3211_11"
        width="59"
        height="76"
        transform="translate(1313 746)"
        fill="url(#pattern-13)"
        data-url="https://shopirbal.com/pt/produto/roda-macica-200-com-freio/"
        data-name="Roda Maciça Ø200 com Freio"
      />
      <rect
        id="PA01.121.13E.3211_19"
        width="51"
        height="59"
        transform="translate(1440 492)"
        fill="url(#pattern-14)"
        data-url="https://shopirbal.com/pt/produto/condensador-700wt/"
        data-name="Condensador 700W"
      />
      <rect
        id="PA01.121.13E.3211_14"
        width="107"
        height="109"
        transform="translate(1437 618)"
        fill="url(#pattern-15)"
        data-url="https://shopirbal.com/pt/produto/motor-ele-700w/"
        data-name="Motor ELE 700W"
      />
      <rect
        id="PA01.121.13E.3211_18"
        width="82"
        height="95"
        transform="translate(1607 503)"
        fill="url(#pattern-16)"
        data-url="https://shopirbal.com/pt/produto/ventoinha-para-motor-700w/"
        data-name="Ventoinha para Motor 700W"
      />
    </g>
  </svg>
)
